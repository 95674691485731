.bgLogin {
  height: 100% !important;
  min-width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right top;
  background-image: url("../../assets/images/png/bg-Login.png");
}
.linha {
  height: 3px;
  width: 100%;
  background-color: #494949;
}
.cardLogin {
  padding-left: 50px;
  padding-right: 70px;
  padding-top: 30px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #000000;
  background-color: #ffffff;
  min-height: 100vh !important;
}
.footerLogin {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  text-align: center;

  font-size: 14px;
}
.logoRede {
  width: 20px;
}

.centroVertical {
  display: flex;
}
.tituloLogin {
  position: absolute;
  top: 75px;
  left: 84px;
  width: 227px;
  height: 98px;
  text-align: left;
  font: normal normal bold 81px/98px Montserrat;
  letter-spacing: -2.43px;
  color: #000000;
  text-transform: uppercase;
  opacity: 1;
}
.subTitulo {
  position: absolute;
  top: 380px;
  left: 84px;
  width: 278px;
  height: 87px;
  text-align: left;
  font: normal normal normal 24px/29px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  text-transform: uppercase;
  opacity: 1;
}
.textoLogin div {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 900;
  font-size: 40px;
  line-height: 50px;
  color: #000000;
}
.textoLogin2 {
  margin-top: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  color: #6b6b6b;
}
.textoLogin3 {
  margin-top: 30px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: #000000;
}
.bgLogin,
.bgLogin input {
  color: #000000 !important;
}
